.person-thumbnail {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: start;
    text-align: left;
}

.person-thumbnail p {
    margin: 0;
}

.person-thumbnail>img {
    width: 100px;
    height: auto;
}

.person-thumbnail>div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}