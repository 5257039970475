.game-list {
    display: flex;
    flex-direction: row-reverse;
    gap: 50px;
    padding: 0 50px;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.game-list>div {
    flex: 0 0 300px;
}

.game-list img {
    width: 100%;
    height: auto;
}