.game-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game-detail>img {
    max-width: 100%;
    height: auto;
}

.game-detail>h2 {
    text-align: center;
}

.game-properties {
    border: lightgray solid 1px;
    padding: 20px 0;
    border-left: none;
    border-right: none;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    width: 100%;
}

.game-properties>div {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
}

.game-properties>div>label {
    cursor: pointer;
    transition: 100ms;
}

.game-properties>div>label:hover {
    transform: scale(1.2);
}

.game-prop {
    display: flex;
    gap: 25px;
    padding: 5%;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
}

.game-prop>img {
    flex: 0 0 500px;
    width: 500px;
    max-width: 100%;
    height: auto;
}

.gallery {
    background: rgba(100, 100, 100, 0.75);
}