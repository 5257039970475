.guestbook-entry {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 40rem;
    max-width: 100%;
    flex: 0 1 auto;
    min-height: 50vh;
}

.guestbook-entry>div {
    width: 100%;
    aspect-ratio: 1577 / 80;
    overflow: visible;
    z-index: 1;
}

.guestbook-entry>div>div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 200%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.guestbook-entry>div:first-child>div {
    background-image: url('./img/wooden_border_top.png');
}

.guestbook-entry>div:last-child {
    display: flex;
    align-items: flex-end;
}

.guestbook-entry>div:last-child>div {
    background-image: url('./img/wooden_border_bottom.png');
}

.guestbook-entry p {
    flex: 1 1 auto;
    background-image: url("./img/background_paper.png");
    background-repeat: repeat-y;
    background-position: center;
    background-size: 100%;
    font-family: NikaRegular;
    text-align: left;
    width: 60%;
    padding: 5% 20%;
    margin: 0;
}

.guestbook-entry button {
    margin-right: 10%;
    margin-bottom: 2%;
}

.guestbook-entry img {
    width: 50%;
    transform: scale(2);
}

.guestbook-entry img:first-child {
    transform-origin: top;
}

.guestbook-entry img:last-child {
    transform-origin: bottom;
}