.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Panton;
}

.App>:nth-child(2) {
  flex: 1 1 auto;
  padding: 0 10%;
}

a>i {
  cursor: pointer;
  font-size: larger;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 100ms;
  display: inline-block;
}

a:hover {
  transform: scale(1.2);
}

p.plain {
  font-family: Roboto, sans-serif;
  overflow: auto;
  max-width: 100%;
}

p.plain a {
  color: #224471;
  text-decoration: underline;
}

.active {
  color: #224471;
}

.fullscreen {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  z-index: 2101;
}