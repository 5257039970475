.guestbook {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guestbook-paginator {
  display: grid;
  grid-template-columns: auto 1fr auto;
  width: 100%;
}

.guestbook-paginator>.nav-button {
  cursor: pointer;
  transition: 100ms;
  max-height: 5vh;
  width: auto;
}

.guestbook-paginator>.nav-button:hover {
  transform: scale(1.1);
}