.loader {
    display: grid;
    grid-template-columns: min-content min-content;
    height: 300px;
    align-content: end;
    justify-content: center;
}

.loader>div {
    background-color: white;
    border: solid 5px gray;
    overflow: hidden;
    font-size: small;
    text-align: center;
}

.loader>.none {
    background-color: unset;
    border: unset;
}

.loader>.side {
    width: 100px;
    height: 100px;
}

.loader>.side.back {
    z-index: 1;
    animation-name: scaleback;
}

.loader>.side.front {
    z-index: 2;
    animation-name: scalefront;
}

.loader>.side.positive {
    transform: skewY(30deg);
}

.loader>.side.negative {
    transform: skewY(-30deg);
}

.loader>.bottom {
    width: 80px;
    height: 80px;
    z-index: 0;
    transform: translate(65px, -79px) scaleX(1.7) rotate(45deg);
}

.loader>.top {
    width: 40px;
    height: 80px;
    transform-origin: right;
    animation-timing-function: linear;
}

.loader>.top.back.left {
    z-index: 4;
    animation-name: openbackleft;
}

.loader>.top.back.right {
    animation-name: openbackright;
}

.loader>.top.front.right {
    z-index: 4;
    animation-name: openfrontright;
}

.loader>.top.front.left {
    animation-name: openfrontleft;
}

.loader>div {
    animation-duration: 3s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

@keyframes scaleback {
    0%,10% {
        height: 0px;
        margin-bottom: 56px;
    }
    50%,100% {
        height: 100px;
        margin-bottom: -44px;
    }
}

@keyframes scalefront {
    0%,10% {
        height: 0px;
    }
    50%,100% {
        height: 100px;
    }
}

@keyframes openbackleft {
    0%,50% {
        transform: translate(5px, 137px) scaleX(1.7) rotate(45deg) scaleX(-1);
    }
    60% {
        transform: translate(5px, 137px) scaleX(1.7) rotate(45deg) scaleX(0.5) skewY(45deg);
    }
    62.5% {
        transform: translate(5px, 137px) scaleX(1.7) rotate(45deg) skewY(45deg);
    }
    75%,100% {
        transform: translate(5px, 137px) scaleX(1.7) rotate(45deg);
    }
}

@keyframes openbackright {
    0%,60% {
        z-index: 3;
        transform: translate(5px, 136px) scaleX(1.7) rotate(-45deg);
    }
    70% {
        z-index: 5;
        transform: translate(5px, 136px) scaleX(1.7) rotate(-45deg) scaleX(0.3) skewY(45deg);
    }
    80% {
        z-index: 5;
        transform: translate(5px, 136px) scaleX(1.7) rotate(-45deg) scaleX(-1) skewY(45deg);
    }
    85%,100% {
        z-index: 5;
        transform: translate(5px, 136px) scaleX(1.7) rotate(-45deg) scaleX(-1);
    }
}

@keyframes openfrontleft {
    0%,60% {
        z-index: 3;
        transform: translate(5px, 112px) scaleX(1.7) rotate(-45deg) scaleX(-1);
    }
    65% {
        z-index: 5;
        transform: translate(5px, 112px) scaleX(1.7) rotate(-45deg) scaleX(-1) skewY(45deg);
    }
    75% {
        z-index: 5;
        transform: translate(5px, 112px) scaleX(1.7) rotate(-45deg) scaleX(0.3) skewY(45deg);
    }
    85%,100% {
        z-index: 5;
        transform: translate(5px, 112px) scaleX(1.7) rotate(-45deg);
    }
}

@keyframes openfrontright {
    0%,50% {
        transform: translate(5px, 112px) scaleX(1.7) rotate(45deg);
    }
    62.5% {
        transform: translate(5px, 112px) scaleX(1.7) rotate(45deg) skewY(45deg);
    }
    65% {
        transform: translate(5px, 112px) scaleX(1.7) rotate(45deg) scaleX(0.5) skewY(45deg);
    }
    75%,100% {
        transform: translate(5px, 112px) scaleX(1.7) rotate(45deg) scaleX(-1);
    }
}