.header>div {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    padding: 0 20px;
    display: grid;
    grid-template-columns: min-content min-content 1fr;
    color: white;
    gap: 10px;
    align-items: start;
}

.header>div>h1 {
    align-self: center;
}

.header>div>div {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    justify-content: right;
    padding-top: 10px;
}

.header>div>select {
    margin-top: 10px;
}

.header>img {
    z-index: -1;
    height: auto;
    width: 100%;
}

.header .menu {
    cursor: pointer;
    font-size: xx-large;
}

.header .header-links {
    background-color: #224471;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 5%;
    width: 90%;
}

.header .header-links>i {
    cursor: pointer;
    font-size: xx-large;
}