.header-background {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    display: none;
}

.header-background>img {
    min-width: 100%;
    height: auto;
}