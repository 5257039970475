.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding-top: 50px;
}

.footer>div {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.footer>label {
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
}